import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-lg text-base font-normal ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: `text-foreground transition-shadow bg-button px-8 py-2`,
        destructive: "bg-destructive border-none text-destructive-foreground",
        outline: `md:max-w-full bg-transparent text-[12px] border-[rgba(78,174,123,1)]`,
        secondary: `text-black text-sm`,
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        applyPromocode:
          "text-sm border-none bg-background opacity-75 hover:bg-neutral-900 hover:opacity-100 transition-all duration-300",
        lightGreen:
          "bg-primary hover:shadow-custom-light text-black text-sm leading-4",
        silverAccent:
          "bg-gradient-to-b from-[#5B6167] to-[#25252F] px-8 py-2 h-fit text-foreground text-base font-bold",
      },
      size: {
        default: "h-8 px-6",
        sm: "h-9 px-3",
        lg: " h-[50px] px-6",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
